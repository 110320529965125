import {
  Typography,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

import { actionScrollToElement } from "../../utils/navUtils";

import "./AppFooter.scss";

const AppFooter = () => {
  return (
    <section className="app-footer-wrapper">
      <div className="app-footer-container">
        <Grid container spacing={2} className="app-footer-content">
          <Grid xs={12} md={4} className="app-footer-contentItem">
            <div>
              <Typography
                variant="h4"
                gutterBottom
                className="app-footer-infoHeadText"
              >
                RRR Cabs
              </Typography>
              <Typography
                variant="caption"
                gutterBottom
                className="app-footer-sloganText"
              >
                Excellence and Beyond, Travel securely with us, Be comfortable
                again.
              </Typography>
            </div>
          </Grid>
          <Grid
            xs={12}
            md={4}
            className="app-footer-contentItem app-footer-contentItemQuickMenu"
          >
            <div>
              <Typography
                variant="h4"
                gutterBottom
                className="app-footer-infoHeadText"
              >
                Quick Menu
              </Typography>
            </div>
            <div>
              <MenuList className="footer-contactMenuList">
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemText
                    onClick={(ev) => {
                      actionScrollToElement(ev, "Home");
                    }}
                  >
                    Home
                  </ListItemText>
                </MenuItem>
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemText
                    onClick={(ev) => {
                      actionScrollToElement(ev, "About Us");
                    }}
                  >
                    About Us
                  </ListItemText>
                </MenuItem>
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemText
                    onClick={(ev) => {
                      actionScrollToElement(ev, "Our services");
                    }}
                  >
                    Our Services
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </div>
          </Grid>
          <Grid
            xs={12}
            md={4}
            className="app-footer-contentItem app-footer-contentItemContactInfo"
            id="contact-us"
          >
            <div>
              <Typography
                variant="h4"
                gutterBottom
                className="app-footer-infoHeadText"
              >
                Contact Info
              </Typography>
            </div>
            <div>
              <MenuList className="footer-contactMenuList">
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemIcon className="footer-contactMenuListItemIcon">
                    <HomeIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Riyaz basha,
                    <br /> Bommanahalli,
                    <br /> Bangalore,
                    <br /> Karnataka - 560076.
                  </ListItemText>
                </MenuItem>
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemIcon className="footer-contactMenuListItemIcon">
                    <EmailIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>rrrcabs7@gmail.com</ListItemText>
                </MenuItem>
                <MenuItem className="footer-contactMenuListItem">
                  <ListItemIcon className="footer-contactMenuListItemIcon">
                    <PhoneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    +91 - 9788045552
                    <br />
                    +91 - 8825755825
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </div>
          </Grid>

          <Grid xs={12} md={12} className="app-footer-contentItem">
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="app-footer-copyrightText"
            >
              &#169; Copyrights 2023 RRR Cab. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AppFooter;
