import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

import AppNavDrawer from "./AppNavDrawer";

import { actionScrollToElement } from "../../utils/navUtils";

import { NAV_ITEMS } from "./AppHeader.constant";

import logo from "../../assets/img/logo.png";

import "./AppHeader.scss";

const AppHeader = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <div className="app-header">
      <header className="app-header-content">
        <AppBar
          component="nav"
          className="app-header-appBar"
          id="app-rrr-cabs-header-id"
        >
          <div className="app-headContact">
            <Typography variant="body1" className="app-headMailText">
              <EmailIcon className="app-headPhoneIcon" /> rrrcabs7@gmail.com
            </Typography>
            <Typography variant="body1" className="app-headPhoneText">
              <CallIcon className="app-headPhoneIcon" /> +91-9788045552
            </Typography>
          </div>
          <Toolbar className="app-header-toolbar">
            <Box
              sx={{ display: { xs: "none", sm: "flex" } }}
              className="header-ds-menu"
            >
              {NAV_ITEMS.map((item, itemIndex) => {
                let _tempItem = [
                  <Button
                    key={item.menuLabel}
                    className="app-header-menuText"
                    onClick={(e) => {
                      actionScrollToElement(e, item.menuLabel);
                    }}
                  >
                    {item.menuLabel}
                  </Button>,
                ];
                if (itemIndex === 1) {
                  _tempItem.push(
                    <div key={`app-header-menu-${item.menuLabel}`}>
                      <img
                        src={logo}
                        className="header-logo-img"
                        alt="app logo"
                        width={120}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                          });
                          window.location.reload();
                        }}
                      />
                    </div>
                  );
                }
                return _tempItem;
              })}
            </Box>
            <Box
              sx={{ display: { xs: "flex", sm: "none" } }}
              className="header-mb-menu"
            >
              <Typography variant="h6" component="div">
                <img
                  src={logo}
                  className="header-logo-img"
                  alt="app logo"
                  width={90}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                    });
                    window.location.reload();
                  }}
                />
              </Typography>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                className="header-menu-icon"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <AppNavDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            actionScrollToElement={actionScrollToElement}
          />
        </Box>
      </header>
    </div>
  );
};

export default AppHeader;
