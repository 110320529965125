import {
  Drawer,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Icon from "@mui/material/Icon";

import { DRAWER_WIDTH, NAV_ITEMS } from "./AppHeader.constant";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  handleDrawerToggle: () => void;
  actionScrollToElement: (e: any, sectionName: string) => void;
  mobileOpen: boolean;
}

const AppNavDrawer = (props: Props) => {
  const { window, mobileOpen, handleDrawerToggle, actionScrollToElement } =
    props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      className="app-drawer"
      anchor="right"
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: DRAWER_WIDTH,
        },
      }}
    >
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ mt: 1, color: "#016286" }}>
          RRR Cabs
        </Typography>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ mb: 1, color: "#525252" }}
        >
          Cab Booking Services Near Me
        </Typography>
        <Divider />
        <List>
          {NAV_ITEMS.map((item) => (
            <ListItem key={item.menuLabel} disablePadding>
              <ListItemButton
                sx={{ textAlign: "left" }}
                onClick={(e) => {
                  actionScrollToElement(e, item.menuLabel);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon>
                  <Icon className="app-drawer-menuListIcon">
                    {item.menuIcon}
                  </Icon>
                </ListItemIcon>
                <ListItemText
                  className="app-drawer-menuListText"
                  primary={item.menuLabel}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default AppNavDrawer;
