export const DRAWER_WIDTH = "50%";
export const NAV_ITEMS = [
  {
    menuLabel: "Home",
    menuIcon: "home",
  },
  {
    menuLabel: "About Us",
    menuIcon: "info",
  },
  {
    menuLabel: "Our Services",
    menuIcon: "local_taxi",
  },
  {
    menuLabel: "Contact Us",
    menuIcon: "call",
  },
];
