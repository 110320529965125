import { Grid, Typography } from "@mui/material";

import AboutCompanyCarImg from "../../assets/img/about-company-car.png";

import "./AboutCompany.scss";

const AboutCompany = () => {
  return (
    <section className="about-company-wrapper" id="about-us">
      <div className="about-company-container">
        <Grid container spacing={2} className="about-company-content">
          <Grid item xs={12} sm={12} md={5} className="about-company-pic">
            <img
              className="about-company-img"
              src={AboutCompanyCarImg}
              width={"100%"}
              alt="about company car"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} className="about-company-info">
            <Typography
              variant="h4"
              gutterBottom
              className="about-company-infoHeadText"
            >
              A Little About Us
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="about-company-infoParaText"
            >
              We are one among the best and trusted cab services provider in
              Bangalore, India.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="about-company-infoParaText"
            >
              Our mission is to provide convenient and efficient transportation
              solutions that meet the diverse needs of individuals, families,
              and businesses.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="about-company-infoParaText"
            >
              As a company, we prioritize safety, comfort, and quality. Our
              fleet consists of well-maintained vehicles that undergo regular
              inspections to ensure optimal performance and passenger safety. We
              have carefully selected a team of experienced and courteous
              drivers who are committed to providing a smooth and enjoyable
              journey for every passenger.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="about-company-infoParaText"
            >
              In addition to our cab service, we offer a wide range of rental
              car options to cater various travel requirements. Whether you are
              planning a business trip, a family/group vacation, special events
              or simply need a temporary replacement vehicle, our diverse fleet
              has something for everyone.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="about-company-infoParaText"
            >
              We aim to make the rental process as seamless as possible, with
              flexible booking options and competitive pricing.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AboutCompany;
