import AppHeader from "../../components/AppHeader/AppHeader";
import AppBanner from "../../components/AppBanner/AppBanner";
import VehiclesCategories from "../../components/VehiclesCategories/VehiclesCategories";
import AboutCompany from "../../components/AboutCompany/AboutCompany";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
// import PopularRoutes from "../../components/PopularRoutes/PopularRoutes";
import OurServices from "../../components/OurServices/OurServices";
import AppFooter from "../../components/AppFooter/AppFooter";

import "./Home.scss";

const Home = () => {
  return (
    <div className="rrrc-home-wrapper">
      <AppHeader />
      <AppBanner />
      <VehiclesCategories />
      <AboutCompany />
      <WhyChooseUs />
      {/* <PopularRoutes /> */}
      <OurServices />
      <AppFooter />
    </div>
  );
};

export default Home;
