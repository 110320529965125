export const actionScrollToElement = (e: any, sectionName: string) => {
  e.preventDefault(); // Stop Page Reloading
  e.stopPropagation();
  let sectionElement = document.getElementById(
    sectionName.toLowerCase().trim().replaceAll(" ", "-")
  );
  const headElemet = document.getElementById("app-rrr-cabs-header-id");
  const headerOffset = headElemet && headElemet.offsetHeight;
  const elementPosition =
    sectionElement && sectionElement.getBoundingClientRect().top;
  if (elementPosition && headerOffset) {
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};
