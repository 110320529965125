import { Grid, Typography } from "@mui/material";

import OutstationRidesImg from "../../assets/img/outstation-rides-img.png";
import CorporateRidesImg from "../../assets/img/corporate-rides.png";
import AirportRidesImg from "../../assets/img/airport-rides.png";
import ComfortRidesImg from "../../assets/img/comfort-rides.png";

const OurServicesDesktop = () => {
  return (
    <Grid
      container
      spacing={2}
      className="ourServices-content"
      sx={{ display: { xs: "none", sm: "flex" } }}
    >
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <Typography variant="h6" gutterBottom className="ourServices-HeadText">
          <span className="yellow-heading-text">Airport</span>
          <span className="blue-heading-text"> Pickup & Drops</span>
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          Make your airport travels hassle-free and save your time, prompt and
          punctual with our reliable Airport Pickup & Drop services.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <img
          src={AirportRidesImg}
          alt="outstation rides"
          className="ourServices-infoImg"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <img
          src={OutstationRidesImg}
          alt="outstation rides"
          className="ourServices-infoImg"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <Typography variant="h6" gutterBottom className="ourServices-HeadText">
          <span className="yellow-heading-text">Holiday</span>
          <span className="blue-heading-text"> Packages</span>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className="ourServices-subHeadText"
        >
          Experience the joy of exploring your dream destination at your own
          pace with our Car Rental service.
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          We offer a wide range of well-maintained and reliable vehicles that
          are perfect for your holiday adventures. Choose from a variety of
          vehicles, including sedans, SUVs, and vans, to suit your travel needs
          and group size.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <Typography variant="h6" gutterBottom className="ourServices-HeadText">
          <span className="yellow-heading-text">Corporate</span>
          <span className="blue-heading-text"> Cab Services</span>
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          Our corporate cab services are designed to cater specifically to the
          unique needs of corporate clients, providing seamless and efficient
          transportation solutions.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <img
          src={CorporateRidesImg}
          alt="Airport rides"
          className="ourServices-infoImg"
        />
      </Grid>
      <Grid item xs={12} sm={6} className="ourServices-contentItem">
        <img
          src={ComfortRidesImg}
          alt="outstation rides"
          className="ourServices-infoImg"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className="ourServices-contentItem ourServices-contentItemComfort"
      >
        <Typography variant="h6" gutterBottom className="ourServices-HeadText">
          <span className="blue-heading-text">Your</span>
          <span className="yellow-heading-text"> Comfort Ride</span>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className="ourServices-subHeadText"
        >
          Outstation Car Rental
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          Book Outstation cabs in Bangalore at a reasonable cost to enjoy your
          holidays hassle-free drive.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className="ourServices-subHeadText"
        >
          Local Trip
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          Rent car/cab at affordable rates for local trips from anywhere and at
          anytime at RRR Cabs.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className="ourServices-subHeadText"
        >
          Cab for Wedding Parties
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="ourServices-ParaText"
        >
          Book a Cab for best wedding car rental to celebrate the wedding party
          in style and elegance.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OurServicesDesktop;
