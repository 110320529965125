import { Typography, Card, CardMedia } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import EconomyCar from "../../assets/img/economy-car.png";
import SedanCar from "../../assets/img/sedan-car.png";
import SUVCar from "../../assets/img/suv-car.png";
import TempoTraveller from "../../assets/img/tempo-traveller.png";
import VolvoBus from "../../assets/img/volvo-bus.png";

import "./VehiclesCategories.scss";

const VehiclesCategories = () => {
  return (
    <div className="vehiclesCategories-wrapper">
      <div className="vehiclesCategories-container">
        <div>
          <Typography
            variant="h4"
            gutterBottom
            className="vehiclesCategories-infoHeadText"
          >
            Catering Vehicle Categories
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className="vehiclesCategories-paraText"
          >
            We offer a diverse range of vehicle categories to cater to the
            unique needs and preferences of our customers. Each category is
            designed to provide comfort, style, and functionality, ensuring a
            pleasant and enjoyable travel experience. Here are some of the
            vehicle categories available.
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4} className="vechiclesCategories-content">
            <Card className="vechiclesCategories-contentCard">
              <Typography
                variant="h6"
                className="vechiclesCategories-contentHeadText"
                gutterBottom
              >
                Economy/Compact Cars
              </Typography>
              <CardMedia
                className="vechiclesCategories-contentImg vechiclesCategories-contentEconomyImg"
                component="img"
                image={EconomyCar}
                alt="Economy Cars"
                height={200}
              />
              <Typography
                variant="body2"
                className="vechiclesCategories-contentDescriptionText"
                gutterBottom
              >
                Ideal for budget-conscious travellers for an efficient and
                affordable option. A perfect choice for short commutes such as
                city driving, airport pickup or drop, shorter or longer trips.
              </Typography>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} className="vechiclesCategories-content">
            <Card className="vechiclesCategories-contentCard">
              <Typography
                variant="h6"
                className="vechiclesCategories-contentHeadText"
                gutterBottom
              >
                Luxury Cars
              </Typography>
              <CardMedia
                className="vechiclesCategories-contentImg"
                component="img"
                image={SedanCar}
                alt="Luxury Cars"
                height={200}
              />
              <Typography
                variant="body2"
                className="vechiclesCategories-contentDescriptionText"
                gutterBottom
              >
                Ideal for those seeking ultimate comfort, style, and
                sophistication. A perfect choice for special events such as
                wedding or anniversary, business travel, romantic getaways, VIP
                transportation, photoshoots.
              </Typography>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} className="vechiclesCategories-content">
            <Card className="vechiclesCategories-contentCard">
              <Typography
                variant="h6"
                className="vechiclesCategories-contentHeadText"
                gutterBottom
              >
                SUVs & MUVs
              </Typography>
              <CardMedia
                className="vechiclesCategories-contentImg"
                component="img"
                image={SUVCar}
                alt="SUVs and MUVs"
                height={200}
              />
              <Typography
                variant="body2"
                className="vechiclesCategories-contentDescriptionText"
                gutterBottom
              >
                Ideal for families or groups with a need for extra space and
                versatility. A perfect choice for family vacations, outdoor
                adventures, group travel, winter travel, special events and
                celebrations, business travel.
              </Typography>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} className="vechiclesCategories-content">
            <Card className="vechiclesCategories-contentCard">
              <Typography
                variant="h6"
                className="vechiclesCategories-contentHeadText"
                gutterBottom
              >
                Vans & Minivans
              </Typography>
              <CardMedia
                className="vechiclesCategories-contentImg"
                component="img"
                image={TempoTraveller}
                alt="Minivans"
                height={200}
              />
              <Typography
                variant="body2"
                className="vechiclesCategories-contentDescriptionText"
                gutterBottom
              >
                Ideal for larger group or require extra space for passengers and
                luggage. A perfect choice for group travel, family outings,
                sports events or team activities, corporate or business
                transportation, special events and celebrations
              </Typography>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} className="vechiclesCategories-content">
            <Card className="vechiclesCategories-contentCard">
              <Typography
                variant="h6"
                className="vechiclesCategories-contentHeadText"
                gutterBottom
              >
                Buses
              </Typography>
              <CardMedia
                className="vechiclesCategories-contentImg"
                component="img"
                image={VolvoBus}
                alt="Luxury buses"
                height={200}
              />
              <Typography
                variant="body2"
                className="vechiclesCategories-contentDescriptionText"
                gutterBottom
              >
                Ideal for traveling together as a group, eliminating the need
                for multiple vehicles and reducing the overall transportation
                costs. A perfect choice for group excursions and tours, school
                and college events, weddings and special celebrations.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default VehiclesCategories;
