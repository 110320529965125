import { Typography, Button } from "@mui/material";

import { actionScrollToElement } from "../../utils/navUtils";

import "./AppBanner.scss";

const AppBanner = () => {
  return (
    <section className="app-banner-wrapper" id="home">
      <div className="app-banner-container">
        <div className="app-banner-content">
          <Typography variant="h4" gutterBottom className="app-banner-headText">
            Welcome to our premier cab &amp; rental car service!
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className="app-banner-paraText"
          >
            Whether you're in need of a{" "}
            <strong>
              quick ride across town, airport pickup & drop, planning a road
              trip adventure, outstation cabs, local trip, holiday packages,
              corporate cab services, wedding car rental,
            </strong>{" "}
            we have you covered. With our reliable and efficient cab service,
            and a wide range of rental car options, we strive to make your
            transportation experience seamless and hassle-free.
          </Typography>
          <div className="app-banner-buttonWrap">
            <Button
              variant="outlined"
              onClick={(e) => {
                // actionScrollToElement(e, "Popular Routes");
                actionScrollToElement(e, "Our Features");
              }}
            >
              Our Features
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBanner;
