import { Typography } from "@mui/material";

import OurServicesDesktop from "./OurServicesDesktop";
import OurServicesMobile from "./OurServicesMobile";

import "./OurServices.scss";

const OurServices = () => {
  return (
    <section className="ourServices-wrapper" id="our-services">
      <div className="ourServices-container">
        <div>
          <Typography
            variant="h4"
            gutterBottom
            className="ourServices-infoHeadText"
          >
            Few popular services
          </Typography>
        </div>
        <OurServicesDesktop />
        <OurServicesMobile />
      </div>
    </section>
  );
};

export default OurServices;
