import React from "react";

import Home from "./pages/Home/Home";

import "./App.css";

function App() {
  return (
    <section className="rrr-cabs-app">
      <div className="rrrc-app-home">
        <Home />
      </div>
    </section>
  );
}

export default App;
