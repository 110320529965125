import { Grid, Typography, Card } from "@mui/material";
import Icon from "@mui/material/Icon";

import { WHY_CHOOSE_US_DATA } from "./WhyChooseUs.constant";

import "./WhyChooseUs.scss";

const WhyChooseUs = () => {
  return (
    <section className="whyChooseUs-wrapper" id="our-features">
      <div className="whyChooseUs-container">
        <div>
          <Typography
            variant="h4"
            gutterBottom
            className="whyChooseUs-infoHeadText"
          >
            It is our PRIDE to give you some key features
          </Typography>
        </div>
        <Grid container spacing={2} className="whyChooseUs-content">
          {WHY_CHOOSE_US_DATA.map((whyChooseUsItem, whyChooseUsIndex) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={`whyChooseUs-contentCard-${whyChooseUsIndex}`}
                className="whyChooseUs-contentGrid"
              >
                <Card className="whyChooseUs-contentCard">
                  <div className="whyChooseUs-contentCardIcon">
                    <Icon className="whyChooseUs-contentCardIconItem">
                      {whyChooseUsItem.iconElem}
                    </Icon>
                  </div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className="whyChooseUs-infoHeadText"
                  >
                    {whyChooseUsItem.headText}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className="whyChooseUs-infoParaText"
                  >
                    {whyChooseUsItem.paraText}
                  </Typography>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </section>
  );
};

export default WhyChooseUs;
