export const WHY_CHOOSE_US_DATA = [
  {
    iconElem: "airplane_ticket",
    headText: "Airport Transfers & Pickups",
    paraText:
      "We specialize in reliable and efficient transportation to and from airports, catering to both individual and group travel needs with timely and punctual service.",
  },
  {
    iconElem: "people_alt",
    headText: "Professional Drivers",
    paraText: `Our cab service is backed by a team of experienced and courteous drivers who prioritize your safety and
      satisfaction. They are well-trained, licensed, and knowledgeable about the local routes, ensuring a smooth
      and enjoyable journey for you.`,
  },
  {
    iconElem: "headset_mic",
    headText: "24/7 Booking Convenience",
    paraText: `Call us 9788045552 / 8825755825 any time for booking your travel.`,
  },
  {
    iconElem: "local_police",
    headText: "Clean & Well-Maintained Vehicles",
    paraText: `We maintain vehicles with clean interior, undergo regular maintenance & inspections.`,
  },
  {
    iconElem: "local_offer",
    headText: "Competitive Pricing",
    paraText: `We believe that quality transportation should be accessible to all. That is why we offer competitive pricing
    options for both our cab service and rental cars. Enjoy affordable rates without compromising on reliability
    or customer service.`,
  },
  {
    iconElem: "no_crash",
    headText: "Extensive Fleet",
    paraText: `Our fleet boasts a diverse range of vehicles to suit every need and preference. From compact cars for solo
    travellers to spacious SUVs for families or groups, we have the perfect vehicle for you.`,
  },
  {
    iconElem: "car_rental",
    headText: "Flexible Rental Options",
    paraText: `Whether you need a car for a few hours, a day, a week, or even longer, we have flexible rental options to
    accommodate your requirements.`,
  },
  {
    iconElem: "roofing",
    headText: "Home Pickups",
    paraText: `We can pick up you directly at your doorstep to save your time and convenient way to reach your
    destination.`,
  },
  {
    iconElem: "sentiment_very_satisfied",
    headText: "Customer Satisfaction",
    paraText: `Your satisfaction is our top priority. We are committed to delivering excellent customer service and
    exceeding your expectations.`,
  },
  /* {
    iconElem: "roofing",
    headText: "Home Pickups",
    paraText:
      "We can pickup you directly at your doorstep to save your time and convenient way to reach your destination.",
  },
  {
    iconElem: "rocket_launch",
    headText: "Fast & Easy Booking",
    paraText:
      "Book your car online or offline. Follow the easy process to book your car online. Or just call us any time form anywhere.",
  },
  {
    iconElem: "headset_mic",
    headText: "24/7 Customer Care",
    paraText:
      "Our dedicated customer support team will always help you to solve any of your queries. Call #9788045552 / #8825755825 or drop mail to rrrcabs7@gmail.com.",
  },
  {
    iconElem: "verified_user",
    headText: "Punctual service",
    paraText:
      "We have a clean track record of on time service. Our call executives will get in touch with you to ensure no delays.",
  },
  {
    iconElem: "security",
    headText: "Security and Reliability",
    paraText:
      "Our drivers undergo extensive training to ensure your comfort and safety during the trip. If there are any issues during the trip, we are just a call away.",
  },
  {
    iconElem: "local_police",
    headText: "Safe and Sanitized cabs",
    paraText:
      "Your safety on your trip is our responsibility. We take extra care to sanitize and air our cabs before and after every ride.",
  }, */
];
